<template>
  <div>
    <v-toolbar fixed app flat color="background">
      <v-icon
        @click.stop="toggleSidebar"
      >
        {{ sidbarIcon }}
      </v-icon>
      <v-toolbar-title class="ap-toolbar-title">
        <TheTenantSelect />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <TheToolBarUserIcon class="pr-3" />
      <TheToolBarInfoIcon class="pr-3" v-if="roleAtLeastSuper" />
      <TheToolBarHelpIcon />
    </v-toolbar>

    <TheLeftSidebar :visible="leftVisible" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheToolbar',
  data: () => ({
    leftVisible: true,
  }),
  computed: {
    ...mapGetters('app', [
      'roleAtLeastSuper',
    ]),
    sidbarIcon() {
      return this.leftVisible ? 'chevron_left' : 'chevron_right';
    },
  },
  components: {
    TheTenantSelect: () => import('@/components/TheTenantSelect'),
    TheLeftSidebar: () => import('@/components/TheLeftSidebar'),
    TheToolBarUserIcon: () => import('@/components/TheToolBarUserIcon'),
    TheToolBarInfoIcon: () => import('@/components/TheToolBarInfoIcon'),
    TheToolBarHelpIcon: () => import('@/components/TheToolBarHelpIcon'),
  },
  methods: {
    toggleSidebar() {
      this.leftVisible = !this.leftVisible;
    },
  },
};
</script>
